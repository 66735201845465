<template>
    <v-app>
        <v-container>
            <div class="text-center mx-auto mt-12">
                <v-img src="@/assets/box.svg" width="150" class="mx-auto"></v-img>
                <div class="mt-5">
                    <div class="text-h1">404</div>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title class="text-wrap">
                                Vídeochamada não encontrada
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </div>
            </div>            
        </v-container>
    </v-app>
</template>

<script>
import EmptyState from '@/components/EmptyState.vue';

export default {
    components: {
        
    },
}
</script>